import * as yup from '@graphcms/validation';
import {
  Alert,
  Box,
  Button,
  CheckBox,
  FieldSet,
  Heading,
  Image,
  Inline,
  InputError,
  Radio,
  Stack,
  Text,
} from '@hygraph/baukasten';
import { trans } from 'i18n';
import * as React from 'react';
import { Form } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom-v5-compat';

import {
  AddCircleFill,
  AdminFill,
  CodeBoxFill,
  EditBoxFill,
  FileCopyFill,
  UserReceivedFill,
} from '@hygraph/icons';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { CheckboxGField } from 'components/GField/CheckboxGField';
import { RadioGField } from 'components/GField/RadioGField';
import { StarterTemplate, Template } from 'generated/graphql';
import { useAcceptInvite } from 'modules/invites/hooks';
import { useProjects } from 'modules/projects/hooks';
import { useWelcomeSurvey } from 'modules/projects/hooks/useWelcomeSurvey';
import { validateWithYup } from 'utils';
import hygraphLogo from './hygraph-logo.svg';
import { useSetUserSelection } from 'modules/user/hooks/useSetUserSelection';

function useSetUserSelectionAndNavigate() {
  const navigate = useNavigate();
  const [setUserSelection] = useSetUserSelection();

  return async (route: string) => {
    await setUserSelection({ showWelcomeSurvey: false });
    navigate(route);
  };
}

export const Welcome: React.FC = ({}) => {
  const [step, setStep] = React.useState<'role' | 'usage'>('role');

  const { projectListData, loading, error } = useProjects();

  if (error) {
    throw error;
  }

  const navigate = useSetUserSelectionAndNavigate();
  const onSkip = async () => {
    await navigate('/');
  };

  // INFO: we don't want to show the project data loading state to fetch the templated and pending invites in the background making this transparent for the user
  if (!projectListData && !loading) {
    throw new Error(trans('Error while loading project data'));
  }

  const { templates = [], pendingInvites = [] } = projectListData ?? {};

  const [acceptInvite] = useAcceptInvite();

  // INFO: if the user has a project invite, we want to accept it as soon as it lands on the welcome page
  const hasInvites = pendingInvites.length > 0;
  if (hasInvites) {
    acceptInvite({ code: pendingInvites[0].code });
  }

  return (
    <Inline
      height="100vh"
      gap="0"
      sx={{
        '@media (max-width: 800px)': { flexDirection: 'column !important' },
      }}
    >
      <Stack flex={1} overflow="auto" p="24px">
        <Image
          src={hygraphLogo}
          width="128px"
          height="36px"
          mb="56px"
          alt="Hygraph"
        />
        <Box
          sx={{
            width: '480px',
            '@media (max-width: 800px)': { width: 'auto' },
          }}
          alignSelf="center"
        >
          {step === 'role' && (
            <WelcomeRole onContinue={() => setStep('usage')} onSkip={onSkip} />
          )}
          {step === 'usage' && (
            <WelcomeUsage
              templates={templates}
              redirectToAcceptedInvite={
                hasInvites
                  ? () => navigate(`/${pendingInvites[0].project.id}/master`)
                  : undefined
              }
              onSkip={onSkip}
              onBack={() => setStep('role')}
            />
          )}
        </Box>
      </Stack>
      <Stack
        flex={1}
        sx={{ '@media (max-width: 800px)': { flex: 0.4, padding: 0 } }}
        justifyContent="center"
        alignItems="center"
        pr="64px"
        backgroundColor="#EEF0F9"
        overflow="auto"
      >
        <WelcomeLayout />
      </Stack>
    </Inline>
  );
};

function WelcomeRole({
  onContinue,
  onSkip,
}: {
  userName?: string;
  onContinue: () => void;
  onSkip: () => Promise<void>;
}) {
  const reasonsOptions = [
    {
      title: trans('Create content'),
      icon: EditBoxFill,
    },
    {
      title: trans('Develop and integrate my product'),
      icon: CodeBoxFill,
    },
    {
      title: trans('Manage billing and users for my team'),
      icon: AdminFill,
    },
  ];

  return (
    <Form<{ reasons: string[] }>
      validate={validateWithYup(
        yup.object().shape({ reasons: yup.array().of(yup.string()).nullable() })
      )}
      initialValues={{ reasons: [] }}
      onSubmit={() => {
        // TODO: implement the logic to save the survey data (this would be tackled later)
      }}
    >
      {({ handleSubmit, submitError, errors, touched, values }) => (
        <Stack gap="36" as="form" onSubmit={handleSubmit}>
          <ApolloInlineError error={submitError}>
            {(err, key) => (
              <Alert mb="s" key={key}>
                {err.message || err}
              </Alert>
            )}
          </ApolloInlineError>
          <Stack gap="16">
            <Stack gap="0">
              <Text
                color="neutral.500"
                fontSize="interface"
                lineHeight="16px"
                fontWeight={500}
              >
                {trans('Question {{current}} of {{total}}', {
                  current: 1,
                  total: 2,
                })}
              </Text>
              <Heading as="h3" fontWeight="bold">
                {trans("Let's get started.")}
              </Heading>
              <Heading as="h3" fontWeight="bold">
                {trans('What do you want to do in Hygraph?')}
              </Heading>
            </Stack>
            <FieldSet>
              <Stack gap="16">
                {reasonsOptions.map(({ title, icon }) => (
                  <CheckboxGField
                    key={title}
                    type="checkbox"
                    name="reasons"
                    hideErrors
                    value={title}
                    labelProps={{ width: '100%' }}
                    render={({ input }) => (
                      <Inline
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        border="1px solid"
                        borderColor={input.checked ? '#5D57E1' : '#D0D4E5'}
                        backgroundColor={input.checked ? '#F3F3FF' : 'initial'}
                        padding="14px"
                        borderRadius="4px"
                      >
                        <Inline>
                          <CheckBox {...input} />
                          <Text>{title}</Text>
                        </Inline>
                        <Box as={icon} size="20" />
                      </Inline>
                    )}
                  />
                ))}
              </Stack>
            </FieldSet>
            {errors?.reasons && touched?.reasons && (
              <InputError mt="negS">{errors.reasons}</InputError>
            )}
          </Stack>
          <Inline justifyContent="end">
            <Button size="large" variant="ghost" onClick={onSkip}>
              {trans('Skip survey')}
            </Button>
            <Button
              size="large"
              onClick={onContinue}
              disabled={values.reasons.length === 0}
            >
              {trans('Next')}
            </Button>
          </Inline>
        </Stack>
      )}
    </Form>
  );
}

type Templates = (
  | ({
      __typename: 'StarterTemplate';
    } & StarterTemplate)
  | ({ __typename: 'Template' } & Template)
)[];
function WelcomeUsage({
  templates,
  redirectToAcceptedInvite,
  onBack,
  onSkip,
}: {
  templates: Templates;
  redirectToAcceptedInvite?: () => void;
  onBack: () => void;
  onSkip: () => Promise<void>;
}) {
  const [step, setStep] = React.useState<'practices' | 'join' | null>();
  const navigate = useSetUserSelectionAndNavigate();

  switch (step) {
    case 'practices':
      return (
        <WelcomeUsagePractices
          templates={templates}
          onBack={() => setStep(null)}
          onSkip={onSkip}
        />
      );
    case 'join':
      return (
        <WelcomeUsageJoin redirectToAcceptedInvite={redirectToAcceptedInvite} />
      );
  }

  const reasonsOptions = [
    {
      title: trans('Give me a clean project'),
      description: trans('Perfect if you are experienced with CMSs'),
      icon: AddCircleFill,
      onSubmit: () => navigate('/?newProject=true'),
    },
    {
      title: trans('I want to start from a template'),
      description: trans("Select one of the templates we've prepared for you"),
      icon: FileCopyFill,
      onSubmit: () => setStep('practices'),
    },
    {
      title: trans(`I'm joining the existing project`),
      description: trans(
        'Join an existing project that you have been invited to'
      ),
      icon: UserReceivedFill,
      onSubmit: () => setStep('join'),
    },
  ];

  return (
    <Form<{ reason: string }>
      validate={validateWithYup(
        yup.object().shape({ reason: yup.string().nullable() })
      )}
      initialValues={{ reason: '' }}
      onSubmit={({ reason }) => {
        const selectedReason = reason
          ? reasonsOptions.find(({ title }) => title === reason)
          : null;

        if (selectedReason) {
          selectedReason.onSubmit();
        } else {
          console.error('No reason matched the selected one');
        }
      }}
    >
      {({ handleSubmit, submitError, errors, touched, values }) => (
        <Stack gap="12" as="form" onSubmit={handleSubmit}>
          <ApolloInlineError error={submitError}>
            {(err, key) => (
              <Alert mb="s" key={key}>
                {err.message || err}
              </Alert>
            )}
          </ApolloInlineError>
          <Stack gap="16">
            <Stack gap="0">
              <Text
                color="neutral.500"
                fontSize="interface"
                lineHeight="16px"
                fontWeight={500}
              >
                {trans('Question {{current}} of {{total}}', {
                  current: 2,
                  total: 2,
                })}
              </Text>
              <Heading as="h3" fontWeight="bold">
                {trans('How do you want to start?')}
              </Heading>
            </Stack>
          </Stack>
          <FieldSet>
            <Stack gap="16">
              {reasonsOptions.map(({ title, description, icon }) => (
                <RadioGField
                  key={title}
                  name="reason"
                  hideErrors
                  value={title}
                  labelParentProps={{ width: '100%', display: 'block' }}
                  render={({ input }) => (
                    <Inline
                      alignItems="start"
                      justifyContent="space-between"
                      width="100%"
                      border="1px solid"
                      borderColor={input.checked ? '#5D57E1' : '#D0D4E5'}
                      backgroundColor={input.checked ? '#F3F3FF' : 'initial'}
                      padding="14px"
                      borderRadius="4px"
                    >
                      <Inline>
                        <Radio {...input} />
                        <Stack gap="6px">
                          <Text>{title}</Text>
                          <Text
                            fontSize="tiny"
                            color="neutral.500"
                            sx={{
                              '@media (max-width: 800px)': {
                                maxWidth: '200px',
                              },
                            }}
                          >
                            {description}
                          </Text>
                        </Stack>
                      </Inline>
                      <Box as={icon} size="20" />
                    </Inline>
                  )}
                />
              ))}
            </Stack>
          </FieldSet>
          {errors?.reason && touched?.reason && (
            <InputError mt="negS">{errors.reason}</InputError>
          )}
          <Inline justifyContent="space-between" mt="8px">
            <Button size="large" variant="ghost" onClick={onBack}>
              {trans('Back')}
            </Button>
            <Inline justifyContent="end">
              <Button size="large" variant="ghost" onClick={onSkip}>
                {trans('Skip survey')}
              </Button>
              <Button type="submit" size="large" disabled={!values.reason}>
                {trans('Next')}
              </Button>
            </Inline>
          </Inline>
        </Stack>
      )}
    </Form>
  );
}

function WelcomeUsagePractices({
  templates,
  onBack,
  onSkip,
}: {
  templates: Templates;
  onBack: () => void;
  onSkip: () => Promise<void>;
}) {
  const navigate = useSetUserSelectionAndNavigate();

  const basicTemplates = templates
    .filter(template => template.__typename === 'Template')
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const starterTemplates = templates
    .filter(template => template.__typename === 'StarterTemplate')
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const sortedTemplates = [...starterTemplates, ...basicTemplates];

  return (
    <Form<{ template: string }>
      validate={validateWithYup(
        yup.object().shape({ template: yup.string().nullable() })
      )}
      initialValues={{ template: '' }}
      onSubmit={async ({ template }) => {
        const selectedTemplate = template
          ? sortedTemplates.find(({ name: title }) => title === template)
          : null;

        if (selectedTemplate) {
          await navigate(`/?newTemplate=${selectedTemplate.id}`);
        } else {
          console.error('No template matched the selected one');
        }
      }}
    >
      {({ handleSubmit, submitError, errors, touched, values }) => (
        <Stack gap="12" as="form" onSubmit={handleSubmit}>
          <ApolloInlineError error={submitError}>
            {(err, key) => (
              <Alert mb="s" key={key}>
                {err.message || err}
              </Alert>
            )}
          </ApolloInlineError>
          <Stack gap="16">
            <Heading as="h3" fontWeight="bold">
              {trans('Select one of our most popular templates')}
            </Heading>
          </Stack>
          <FieldSet>
            <Stack gap="16">
              {sortedTemplates.map(({ name: title, description, picture }) => (
                <RadioGField
                  key={title}
                  name="template"
                  hideErrors
                  value={title}
                  labelParentProps={{ width: '100%', display: 'block' }}
                  render={({ input }) => (
                    <Inline
                      alignItems="start"
                      justifyContent="space-between"
                      width="100%"
                      border="1px solid"
                      borderColor={input.checked ? '#5D57E1' : '#D0D4E5'}
                      backgroundColor={input.checked ? '#F3F3FF' : 'initial'}
                      padding="14px"
                      borderRadius="4px"
                    >
                      <Inline>
                        <Radio {...input} />
                        <Stack gap="6px">
                          <Text>{title}</Text>
                          <Text fontSize="tiny" color="neutral.500">
                            {description}
                          </Text>
                        </Stack>
                      </Inline>
                      <Box
                        width="64"
                        height="64"
                        role="presentation"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontSize="30px"
                        letterSpacing="-2px"
                        style={{ textIndent: '-2px' }}
                        fontWeight={600}
                        sx={{ objectFit: 'cover' }}
                        {...(picture
                          ? { as: Image, src: picture }
                          : {
                              bg: 'neutral.900',
                              color: 'white',
                              children: `${title
                                .charAt(0)
                                .toUpperCase()}${title.charAt(1)}`,
                            })}
                      />
                    </Inline>
                  )}
                />
              ))}
            </Stack>
          </FieldSet>
          {errors?.reason && touched?.reason && (
            <InputError mt="negS">{errors.reason}</InputError>
          )}
          <Inline justifyContent="space-between" mt="8px">
            <Button size="large" variant="ghost" onClick={onBack}>
              {trans('Back')}
            </Button>
            <Inline justifyContent="end">
              <Button size="large" variant="ghost" onClick={onSkip}>
                {trans('Skip survey')}
              </Button>
              <Button type="submit" size="large" disabled={!values.template}>
                {trans('Next')}
              </Button>
            </Inline>
          </Inline>
        </Stack>
      )}
    </Form>
  );
}

function WelcomeUsageJoin({
  redirectToAcceptedInvite,
}: {
  redirectToAcceptedInvite?: () => void;
}) {
  if (redirectToAcceptedInvite) {
    redirectToAcceptedInvite();
  }

  const navigate = useSetUserSelectionAndNavigate();

  return (
    <Stack>
      <Text>
        {trans(
          `It seems that you haven't been invited to join a project yet. Talk to your project admin and, in the meantime, you can explore our templates or start from a blank project.`
        )}
      </Text>
      <Button onClick={() => navigate('/')} size="large" alignSelf="end">
        {trans('Continue to Project Directory')}
      </Button>
    </Stack>
  );
}

function WelcomeLayout() {
  const { data, error } = useWelcomeSurvey();

  if (!data || error) return null;
  const { welcomeSurveyCompaniesLogos, welcomeSurveyCasesStudies } = data;
  if (!welcomeSurveyCasesStudies || !welcomeSurveyCompaniesLogos) return null;

  const enabledCaseStudy = welcomeSurveyCasesStudies[0];

  return (
    <>
      <Box
        width="80%"
        textAlign="left"
        backgroundColor="#F6F8FE"
        padding="32px 40px 24px 40px"
        borderRadius="16px"
        mb="48px"
        sx={{
          '@media (max-width: 800px)': { display: 'none', padding: 0 },
        }}
      >
        <Text color="neutral.800" fontSize="20px" fontWeight="500" mb="24px">
          {enabledCaseStudy.title}
        </Text>
        <Inline gap="2">
          <Image
            src={enabledCaseStudy.authorPicture.url}
            alt={enabledCaseStudy.authorName}
            borderRadius="full"
            width="48px"
            height="48px"
            mr="12px"
          />
          <Stack gap="0">
            <Text fontSize="15px" fontWeight="500">
              {enabledCaseStudy.authorName}
            </Text>
            <Text fontSize="14px" color="neutral.600">
              {enabledCaseStudy.authorRole}
            </Text>
          </Stack>
        </Inline>
      </Box>
      <Stack
        gap="0"
        sx={{
          '@media (max-width: 800px)': { mx: '32px' },
        }}
      >
        <Box mb="16px">
          <Text
            fontSize="14px"
            color="neutral.500"
            fontWeight="500"
            textTransform="uppercase"
            textAlign="center"
          >
            {trans('Trusted by')}
          </Text>
        </Box>
        <Inline gap="0" justifyContent="center">
          {welcomeSurveyCompaniesLogos.map(({ id, logo, logoTitle }) => (
            <Image
              key={id}
              src={logo.url}
              alt={logoTitle}
              width="100px"
              height="50px"
            />
          ))}
        </Inline>
      </Stack>
    </>
  );
}
