export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GeoIP: any;
  JSON: any;
  Map: any;
  Time: any;
};

export type AcceptInviteInput = {
  code: Scalars['String'];
};

export type AcceptInvitePayload = {
  __typename: 'AcceptInvitePayload';
  invite: Invite;
};

export type App = IApp & {
  __typename: 'App';
  /** Projects belonging to the current user where App is allowed to be installed */
  allowedProjects?: Maybe<Array<Project>>;
  apiId: Scalars['String'];
  author: Scalars['ID'];
  avatarUrl: Scalars['String'];
  configurationUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  elements?: Maybe<Array<IAppElement>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: AppPermissions;
  publicationStatus: AppPublicationStatus;
  setupUrl: Scalars['String'];
  updatedAt: Scalars['Time'];
  webhookUrl?: Maybe<Scalars['String']>;
};

export enum AppAccessType {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED'
}

export enum AppContentPermission {
  NONE = 'NONE',
  READ = 'READ',
  READ_WRITE = 'READ_WRITE'
}

export type AppElementInput = {
  apiId: Scalars['String'];
  config?: InputMaybe<Scalars['Map']>;
  description?: InputMaybe<Scalars['String']>;
  features?: InputMaybe<Array<FieldAppElementFeature>>;
  fieldType?: InputMaybe<SimpleFieldType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  src: Scalars['String'];
  type: AppElementType;
};

export enum AppElementType {
  field = 'field',
  formSidebar = 'formSidebar',
  page = 'page'
}

export type AppForOwner = IApp & {
  __typename: 'AppForOwner';
  accessType: AppAccessType;
  allowedProjects?: Maybe<Array<Project>>;
  allowedUsers?: Maybe<Array<Scalars['String']>>;
  apiId: Scalars['String'];
  author: Scalars['ID'];
  avatarUrl: Scalars['String'];
  configurationUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  elements?: Maybe<Array<IAppElement>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: AppPermissions;
  publicationStatus: AppPublicationStatus;
  setupUrl: Scalars['String'];
  updatedAt: Scalars['Time'];
  webhookUrl?: Maybe<Scalars['String']>;
};

export type AppPermissions = {
  __typename: 'AppPermissions';
  CONTENT: AppContentPermission;
  SCHEMA: AppSchemaPermission;
  USER: AppUserPermission;
  WEBHOOKS: AppWebhooksPermission;
};

export type AppPermissionsInput = {
  CONTENT?: InputMaybe<AppContentPermission>;
  SCHEMA?: InputMaybe<AppSchemaPermission>;
  USER?: InputMaybe<AppUserPermission>;
  WEBHOOKS?: InputMaybe<AppWebhooksPermission>;
};

export enum AppPublicationStatus {
  PENDING = 'PENDING',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export enum AppSchemaPermission {
  NONE = 'NONE',
  READ = 'READ',
  READ_WRITE = 'READ_WRITE'
}

export type AppToken = {
  __typename: 'AppToken';
  app: App;
  id: Scalars['ID'];
};

export enum AppUserPermission {
  NONE = 'NONE',
  READ = 'READ'
}

export enum AppWebhooksPermission {
  NONE = 'NONE',
  READ_WRITE = 'READ_WRITE'
}

export type AppWithSecrets = IApp & {
  __typename: 'AppWithSecrets';
  accessType: AppAccessType;
  allowedProjects?: Maybe<Array<Project>>;
  allowedUsers?: Maybe<Array<Scalars['String']>>;
  apiId: Scalars['String'];
  author: Scalars['ID'];
  avatarUrl: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  configurationUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  elements?: Maybe<Array<IAppElement>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: AppPermissions;
  publicationStatus: AppPublicationStatus;
  setupUrl: Scalars['String'];
  updatedAt: Scalars['Time'];
  webhookUrl?: Maybe<Scalars['String']>;
};

export type AsyncOperationPayload = {
  __typename: 'AsyncOperationPayload';
  migration: Migration;
};

export type BillingPeriod = {
  __typename: 'BillingPeriod';
  from: Scalars['String'];
  to: Scalars['String'];
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type CloneProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  region: Scalars['String'];
  /** required to clone from a template or a project you are an owner of */
  template: CloneProjectTemplateInput;
};

/** clone project from a template */
export type CloneProjectTemplateInput = {
  /** Set to false to not include content */
  content?: Scalars['Boolean'];
  /** id of template (if it's marked as template) or id of a project you are an owner of */
  templateId: Scalars['ID'];
  /** Set to true to include webhooks. If webhooks are included, they will be disabled initially in the cloned project. */
  webhooks?: Scalars['Boolean'];
};

/** This is used to clone a project that someone made publicly clone-able. */
export type ClonePublicProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** Usually extracted from the public clone url. */
  publicCloneAccessId: Scalars['ID'];
  region: Scalars['String'];
};

export type CloningFrom = Project | StarterTemplate | Template;

export type CloningProject = IPendingProject & {
  __typename: 'CloningProject';
  cloningFrom: CloningFrom;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
};

export enum ColorPalette {
  BROWN = 'BROWN',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  PINK = 'PINK',
  PURPLE = 'PURPLE',
  RED = 'RED',
  TEAL = 'TEAL',
  YELLOW = 'YELLOW'
}

export type CommentingConfig = {
  __typename: 'CommentingConfig';
  token: Scalars['String'];
  url: Scalars['String'];
  userKey: Scalars['String'];
};

export type CommonFilestack = {
  __typename: 'CommonFilestack';
  apiKey: Scalars['String'];
  path: Scalars['String'];
  security: CommonFilestackSecurityOptions;
};

export type CommonFilestackSecurityOptions = {
  __typename: 'CommonFilestackSecurityOptions';
  auth?: Maybe<FilestackSecurityAuthOptions>;
  enabled: Scalars['Boolean'];
};

export type CreateAppInput = {
  accessType?: InputMaybe<AppAccessType>;
  allowedProjects?: InputMaybe<Array<Scalars['String']>>;
  allowedUsers?: InputMaybe<Array<Scalars['String']>>;
  apiId: Scalars['String'];
  avatarUrl: Scalars['String'];
  configurationUrl?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  elements?: InputMaybe<Array<AppElementInput>>;
  name: Scalars['String'];
  permissions?: InputMaybe<AppPermissionsInput>;
  setupUrl: Scalars['String'];
  webhookUrl?: InputMaybe<Scalars['String']>;
};

export type CreateAppPayload = {
  __typename: 'CreateAppPayload';
  createdApp: AppWithSecrets;
};

export type CreateAppSecretsInput = {
  apiId: Scalars['String'];
};

export type CreateAppSecretsPayload = {
  __typename: 'CreateAppSecretsPayload';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type CreateProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  region: Scalars['String'];
  template?: InputMaybe<CreateProjectTemplateInput>;
};

export type CreateProjectPayload = {
  __typename: 'CreateProjectPayload';
  project: Project;
};

export type CreateProjectTemplateInput = {
  content?: InputMaybe<Scalars['Boolean']>;
  templateId: Scalars['ID'];
  webhooks?: InputMaybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  auth0Sub: Scalars['String'];
  email: Scalars['String'];
  geoIp?: InputMaybe<Scalars['GeoIP']>;
  name: Scalars['String'];
  picture?: InputMaybe<Scalars['String']>;
};

export type CreateUserPayload = {
  __typename: 'CreateUserPayload';
  createdUserId: Scalars['String'];
};

export type DeleteAccountPayload = {
  __typename: 'DeleteAccountPayload';
  deletedUserId: Scalars['ID'];
};

export type DeleteAccountThirdPartyPayload = {
  __typename: 'DeleteAccountThirdPartyPayload';
  migrationDownScript: Scalars['String'];
  migrationUpScript: Scalars['String'];
};

export type DeleteAppInput = {
  apiId: Scalars['String'];
};

export type DeleteAppPayload = {
  __typename: 'DeleteAppPayload';
  deletedAppId: Scalars['ID'];
};

export type DeleteProjectInput = {
  id: Scalars['ID'];
};

export type DeleteProjectPayload = {
  __typename: 'DeleteProjectPayload';
  deletedProjectId: Scalars['ID'];
};

export type Environment = {
  __typename: 'Environment';
  authToken: Scalars['String'];
  createdAt: Scalars['Time'];
  deliveryUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  isCloning?: Maybe<Scalars['Boolean']>;
  metrics: Metrics;
  name: Scalars['String'];
  quotas: EnvironmentLevelQuota;
  updatedAt: Scalars['Time'];
};

export type EnvironmentLevelQuota = {
  __typename: 'EnvironmentLevelQuota';
  contentPermissions: Progress;
  locales: Progress;
  models: Progress;
  remoteSources: Progress;
  stages: Progress;
  webhooks: Progress;
};

export type Feedback = {
  __typename: 'Feedback';
  /** Allow contacting the user */
  allowContact?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Time'];
  /** Name of the feature */
  featureName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Feedback Message */
  message?: Maybe<Scalars['String']>;
  /** Rating of the feature */
  rating?: Maybe<Scalars['Int']>;
  /** Reason for deleting project */
  reasons?: Maybe<Array<Scalars['String']>>;
  /** Type of feedback */
  type: FeedbackType;
};

/** Type of feedback */
export enum FeedbackType {
  /** Used when deleting an account */
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  /** Used when deleting a project */
  DELETE_PROJECT = 'DELETE_PROJECT',
  /** Used when downgrading a plan on a project */
  DOWNGRADE_PLAN = 'DOWNGRADE_PLAN',
  /** Used when sending a feedback from a new feature on the webapp */
  FEATURE_FEEDBACK = 'FEATURE_FEEDBACK',
  /** Used when sending a feedback from the feedback form on the webapp */
  GENERAL_FEEDBACK = 'GENERAL_FEEDBACK',
  /** Used when leaving a project */
  LEAVE_PROJECT = 'LEAVE_PROJECT'
}

export type FieldAppElement = IAppElement & {
  __typename: 'FieldAppElement';
  apiId: Scalars['String'];
  app: App;
  config?: Maybe<Scalars['Map']>;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  features: Array<FieldAppElementFeature>;
  fieldType: SimpleFieldType;
  id: Scalars['ID'];
  name: Scalars['String'];
  src: Scalars['String'];
  type: AppElementType;
  updatedAt: Scalars['Time'];
};

export enum FieldAppElementFeature {
  FieldRenderer = 'FieldRenderer',
  ListRenderer = 'ListRenderer',
  TableRenderer = 'TableRenderer'
}

export type Filestack = IAssetConfig & {
  __typename: 'Filestack';
  apiKey: Scalars['String'];
  bucket: Scalars['String'];
  isManagedBucket: Scalars['Boolean'];
  path: Scalars['String'];
  security: FilestackSecurityOptions;
};

export type FilestackSecurityAuthOptions = {
  __typename: 'FilestackSecurityAuthOptions';
  policy: Scalars['String'];
  signature: Scalars['String'];
};

export type FilestackSecurityOptions = {
  __typename: 'FilestackSecurityOptions';
  auth?: Maybe<FilestackSecurityAuthOptions>;
  enabled: Scalars['Boolean'];
  globalExpires: Scalars['String'];
  stageOverrides: Array<StageFilestackSecurityOptions>;
};

export type FormSidebarAppElement = IAppElement & {
  __typename: 'FormSidebarAppElement';
  apiId: Scalars['String'];
  app: App;
  config?: Maybe<Scalars['Map']>;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  src: Scalars['String'];
  type: AppElementType;
  updatedAt: Scalars['Time'];
};

export type IApp = {
  allowedProjects?: Maybe<Array<Project>>;
  apiId: Scalars['String'];
  author: Scalars['ID'];
  avatarUrl: Scalars['String'];
  configurationUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  elements?: Maybe<Array<IAppElement>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: AppPermissions;
  publicationStatus: AppPublicationStatus;
  setupUrl: Scalars['String'];
  updatedAt: Scalars['Time'];
  webhookUrl?: Maybe<Scalars['String']>;
};

export type IAppElement = {
  apiId: Scalars['String'];
  app: App;
  config?: Maybe<Scalars['Map']>;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  src: Scalars['String'];
  type: AppElementType;
  updatedAt: Scalars['Time'];
};

export type IAssetConfig = {
  apiKey: Scalars['String'];
};

export type IPendingProject = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
};

export type ISchemaMigrationPayload = {
  migration: Migration;
};

export type ITemplate = {
  coverPicture?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  resources: Array<TemplateResource>;
};

export type IUser = {
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  profile: Profile;
};

export type IViewer = {
  id: Scalars['ID'];
  project?: Maybe<Project>;
  regions: Array<Region>;
  templates: Array<ITemplate>;
};


export type IViewerProjectArgs = {
  id: Scalars['ID'];
};

export type Invite = {
  __typename: 'Invite';
  acceptedAt?: Maybe<Scalars['Time']>;
  code: Scalars['String'];
  createdAt: Scalars['Time'];
  email: Scalars['String'];
  expirationDate: Scalars['String'];
  expiresAt: Scalars['Time'];
  id: Scalars['ID'];
  issuer?: Maybe<User>;
  origin?: Maybe<Scalars['String']>;
  project: Project;
  roles: Array<Scalars['String']>;
  status: InviteStatus;
};

export enum InviteStatus {
  ACCEPTED = 'ACCEPTED',
  ACCEPTING = 'ACCEPTING',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  REJECTING = 'REJECTING'
}

export type InviteUpdatePayload = {
  __typename: 'InviteUpdatePayload';
  inviteId: Scalars['String'];
  projectId: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type LeaveProjectInput = {
  id: Scalars['ID'];
};

export type LeaveProjectPayload = {
  __typename: 'LeaveProjectPayload';
  leftProjectId: Scalars['ID'];
};

export type LeaveTrialInput = {
  projectId: Scalars['ID'];
};

export type LeaveTrialPayload = {
  __typename: 'LeaveTrialPayload';
  project: Project;
};

export type LegacyProject = {
  __typename: 'LegacyProject';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isMigrated: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type Lifecycle = {
  __typename: 'Lifecycle';
  progress: Scalars['Float'];
  steps?: Maybe<Array<LifecycleStep>>;
};

export type LifecycleStep = {
  __typename: 'LifecycleStep';
  description?: Maybe<Scalars['String']>;
  done: Scalars['Boolean'];
  name: Scalars['String'];
  type: LifecycleStepType;
};

export enum LifecycleStepType {
  API_PERMISSIONS_SET = 'API_PERMISSIONS_SET',
  CONTENT_ADDED = 'CONTENT_ADDED',
  EXPLORE_CONTENT_API = 'EXPLORE_CONTENT_API',
  EXTERNAL_TRAFFIC = 'EXTERNAL_TRAFFIC',
  SCHEMA_SETUP = 'SCHEMA_SETUP'
}

export type Limit = {
  __typename: 'Limit';
  addOnCode?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  name: Scalars['String'];
  plan: Plan;
  type: LimitType;
  updatedAt: Scalars['Time'];
};

export enum LimitType {
  API_OPERATIONS = 'API_OPERATIONS',
  ASSET_TRAFFIC = 'ASSET_TRAFFIC',
  AUDIT_LOGS_RETENTION_PERIOD = 'AUDIT_LOGS_RETENTION_PERIOD',
  CONTENT_MODELS = 'CONTENT_MODELS',
  CONTENT_PERMISSIONS = 'CONTENT_PERMISSIONS',
  CONTENT_STAGES = 'CONTENT_STAGES',
  ENVIRONMENTS = 'ENVIRONMENTS',
  INTEGRATIONS = 'INTEGRATIONS',
  LOCALES = 'LOCALES',
  MODELS = 'MODELS',
  PERMANENT_AUTH_TOKENS = 'PERMANENT_AUTH_TOKENS',
  RATE_LIMIT_PER_SECOND = 'RATE_LIMIT_PER_SECOND',
  RECORDS = 'RECORDS',
  REMOTE_FIELDS = 'REMOTE_FIELDS',
  REMOTE_FIELDS_HTTP_WORKERS = 'REMOTE_FIELDS_HTTP_WORKERS',
  REMOTE_FIELDS_MAX_EXECUTION_TIME = 'REMOTE_FIELDS_MAX_EXECUTION_TIME',
  REMOTE_FIELDS_MAX_RESPONSE_SIZE = 'REMOTE_FIELDS_MAX_RESPONSE_SIZE',
  REMOTE_SOURCES = 'REMOTE_SOURCES',
  ROLES = 'ROLES',
  SCHEDULING_OPERATIONS_IN_RELEASE = 'SCHEDULING_OPERATIONS_IN_RELEASE',
  SCHEDULING_PENDING_OPERATIONS = 'SCHEDULING_PENDING_OPERATIONS',
  SCHEDULING_PENDING_RELEASES = 'SCHEDULING_PENDING_RELEASES',
  SEATS = 'SEATS',
  VERSIONS = 'VERSIONS',
  VERSION_RETENTION_PERIOD = 'VERSION_RETENTION_PERIOD',
  WEBHOOKS = 'WEBHOOKS',
  WORKFLOW_STEPS = 'WORKFLOW_STEPS'
}

export type ManagementPermission = {
  __typename: 'ManagementPermission';
  action: PermissionAction;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MaxComplexityInput = {
  dripRate?: InputMaybe<Scalars['Int']>;
  maxComplexity?: InputMaybe<Scalars['Int']>;
  projectIdentifier: Scalars['String'];
};

export type MaxComplexityPayload = {
  __typename: 'MaxComplexityPayload';
  applied: Scalars['Boolean'];
};

export type MembersAggregate = {
  __typename: 'MembersAggregate';
  count: Scalars['Int'];
};

export type MetaInfo = {
  __typename: 'MetaInfo';
  serverVersion: Scalars['String'];
};

export type Metrics = {
  __typename: 'Metrics';
  apiOperations: Array<Stats>;
  assetTraffic: Array<Stats>;
  assetTransformations: Array<Stats>;
};


export type MetricsApiOperationsArgs = {
  end: Scalars['String'];
  resolution: Scalars['Int'];
  start: Scalars['String'];
};


export type MetricsAssetTrafficArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};


export type MetricsAssetTransformationsArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};

export type Migration = {
  __typename: 'Migration';
  createdAt: Scalars['Time'];
  errors?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Name of the migration in case if was triggered via a named batch migration */
  name?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  operationType: MigrationOperationType;
  /** @deprecated No longer supported */
  resourceId?: Maybe<Scalars['ID']>;
  status: MigrationStatus;
};

export enum MigrationOperationType {
  BATCH = 'BATCH',
  CREATE_ENUMERABLE_FIELD = 'CREATE_ENUMERABLE_FIELD',
  CREATE_ENUMERATION = 'CREATE_ENUMERATION',
  CREATE_ENVIRONMENT = 'CREATE_ENVIRONMENT',
  CREATE_LOCALE = 'CREATE_LOCALE',
  CREATE_MODEL = 'CREATE_MODEL',
  CREATE_PROJECT_FROM_TEMPLATE = 'CREATE_PROJECT_FROM_TEMPLATE',
  CREATE_RELATIONAL_FIELD = 'CREATE_RELATIONAL_FIELD',
  CREATE_REMOTE_FIELD = 'CREATE_REMOTE_FIELD',
  CREATE_REMOTE_TYPE_DEFINITION = 'CREATE_REMOTE_TYPE_DEFINITION',
  CREATE_SIMPLE_FIELD = 'CREATE_SIMPLE_FIELD',
  CREATE_STAGE = 'CREATE_STAGE',
  CREATE_UNION_FIELD = 'CREATE_UNION_FIELD',
  DELETE_ENUMERATION = 'DELETE_ENUMERATION',
  DELETE_FIELD = 'DELETE_FIELD',
  DELETE_LOCALE = 'DELETE_LOCALE',
  DELETE_MODEL = 'DELETE_MODEL',
  DELETE_REMOTE_TYPE_DEFINITION = 'DELETE_REMOTE_TYPE_DEFINITION',
  DELETE_STAGE = 'DELETE_STAGE',
  UPDATE_ENUMERABLE_FIELD = 'UPDATE_ENUMERABLE_FIELD',
  UPDATE_ENUMERATION = 'UPDATE_ENUMERATION',
  UPDATE_LOCALE = 'UPDATE_LOCALE',
  UPDATE_MODEL = 'UPDATE_MODEL',
  UPDATE_RELATIONAL_FIELD = 'UPDATE_RELATIONAL_FIELD',
  UPDATE_REMOTE_TYPE_DEFINITION = 'UPDATE_REMOTE_TYPE_DEFINITION',
  UPDATE_SIMPLE_FIELD = 'UPDATE_SIMPLE_FIELD',
  UPDATE_STAGE = 'UPDATE_STAGE',
  UPDATE_UNION_FIELD = 'UPDATE_UNION_FIELD'
}

export enum MigrationStatus {
  FAILED = 'FAILED',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  TIMEOUT = 'TIMEOUT'
}

export type Mutation = {
  __typename: 'Mutation';
  acceptInvite: AcceptInvitePayload;
  cloneProject: Project;
  clonePublicProject: ProjectPublicClonePayload;
  createApp: CreateAppPayload;
  createAppSecrets: CreateAppSecretsPayload;
  createProject: CreateProjectPayload;
  createUser: CreateUserPayload;
  deleteAccount: DeleteAccountPayload;
  deleteAccountThirdParty: DeleteAccountThirdPartyPayload;
  deleteApp: DeleteAppPayload;
  deleteProject: DeleteProjectPayload;
  leaveProject: LeaveProjectPayload;
  leaveTrial: LeaveTrialPayload;
  rejectInvite: RejectInvitePayload;
  reserveProjects: _ReserveProjectsPayload;
  sendFeedback: Feedback;
  sendInvite: SendInvitePayload;
  sendUserFeedback: UserFeedback;
  setUserAnalytics: UserAnalytics;
  setUserSelection: UserSelection;
  startTrial: StartTrialPayload;
  switchOwner: _SwitchOwnerPayload;
  switchPaymentSubscription: SwitchPaymentSubscriptionPayload;
  track: TrackPayload;
  updateApp: UpdateAppPayload;
  updateAppSecrets: UpdateAppSecretsPayload;
  updateProject: Project;
  updateUserProfile: User;
};


export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};


export type MutationCloneProjectArgs = {
  data: CloneProjectInput;
};


export type MutationClonePublicProjectArgs = {
  input: ClonePublicProjectInput;
};


export type MutationCreateAppArgs = {
  input: CreateAppInput;
};


export type MutationCreateAppSecretsArgs = {
  data: CreateAppSecretsInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateUserArgs = {
  data: CreateUserInput;
};


export type MutationDeleteAccountThirdPartyArgs = {
  userEmail: Scalars['String'];
};


export type MutationDeleteAppArgs = {
  input: DeleteAppInput;
};


export type MutationDeleteProjectArgs = {
  data: DeleteProjectInput;
};


export type MutationLeaveProjectArgs = {
  data: LeaveProjectInput;
};


export type MutationLeaveTrialArgs = {
  data: LeaveTrialInput;
};


export type MutationRejectInviteArgs = {
  input: RejectInviteInput;
};


export type MutationReserveProjectsArgs = {
  data: _ReserveProjectsInput;
};


export type MutationSendFeedbackArgs = {
  data: SendFeedbackInput;
};


export type MutationSendInviteArgs = {
  data: SendInviteInput;
};


export type MutationSendUserFeedbackArgs = {
  data: SendUserFeedbackInput;
};


export type MutationSetUserAnalyticsArgs = {
  data: SetUserAnalyticsInput;
};


export type MutationSetUserSelectionArgs = {
  data: SetUserSelectionInput;
};


export type MutationStartTrialArgs = {
  data: StartTrialInput;
};


export type MutationSwitchOwnerArgs = {
  data: _SwitchOwnerInput;
};


export type MutationSwitchPaymentSubscriptionArgs = {
  data: SwitchPaymentSubscriptionInput;
};


export type MutationTrackArgs = {
  data: TrackInput;
};


export type MutationUpdateAppArgs = {
  input: UpdateAppInput;
};


export type MutationUpdateAppSecretsArgs = {
  input: UpdateAppSecretsInput;
};


export type MutationUpdateProjectArgs = {
  data: UpdateProjectInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type PageAppElement = IAppElement & {
  __typename: 'PageAppElement';
  apiId: Scalars['String'];
  app: App;
  config?: Maybe<Scalars['Map']>;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  src: Scalars['String'];
  type: AppElementType;
  updatedAt: Scalars['Time'];
};

export type PageInfo = {
  __typename: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
};

export type PaymentAccount = {
  __typename: 'PaymentAccount';
  accountManagementUrl?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  hostedBillingUrl?: Maybe<Scalars['String']>;
  hostedPageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isClosed: Scalars['Boolean'];
  isMain: Scalars['Boolean'];
  paymentSubscriptions: Array<PaymentSubscription>;
  updatedAt: Scalars['Time'];
  user: IUser;
};


export type PaymentAccountHostedPageUrlArgs = {
  planName: Scalars['String'];
  projectId: Scalars['ID'];
};

export type PaymentSubscription = {
  __typename: 'PaymentSubscription';
  billingPeriod: BillingPeriod;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  isCanceled: Scalars['Boolean'];
  paymentAccount: PaymentAccount;
  plan: Plan;
  projects: Array<Project>;
  renewsAt?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
};

export enum PermissionAction {
  APP_CREATE = 'APP_CREATE',
  APP_DELETE = 'APP_DELETE',
  APP_INSTALLATION_CREATE = 'APP_INSTALLATION_CREATE',
  APP_INSTALLATION_DELETE = 'APP_INSTALLATION_DELETE',
  APP_INSTALLATION_UPDATE = 'APP_INSTALLATION_UPDATE',
  APP_UPDATE = 'APP_UPDATE',
  AUDIT_LOGS_READ = 'AUDIT_LOGS_READ',
  COMPONENT_CREATE = 'COMPONENT_CREATE',
  COMPONENT_DELETE = 'COMPONENT_DELETE',
  COMPONENT_READ = 'COMPONENT_READ',
  COMPONENT_UPDATE = 'COMPONENT_UPDATE',
  CONTENTVIEW_CREATE = 'CONTENTVIEW_CREATE',
  CONTENTVIEW_DELETE = 'CONTENTVIEW_DELETE',
  CONTENTVIEW_READ = 'CONTENTVIEW_READ',
  CONTENTVIEW_SYSTEM_UPDATE = 'CONTENTVIEW_SYSTEM_UPDATE',
  CONTENTVIEW_UPDATE = 'CONTENTVIEW_UPDATE',
  CONTENT_CREATE = 'CONTENT_CREATE',
  CONTENT_DELETE = 'CONTENT_DELETE',
  CONTENT_PERMISSION_CREATE = 'CONTENT_PERMISSION_CREATE',
  CONTENT_PERMISSION_DELETE = 'CONTENT_PERMISSION_DELETE',
  CONTENT_PERMISSION_READ = 'CONTENT_PERMISSION_READ',
  CONTENT_PERMISSION_UPDATE = 'CONTENT_PERMISSION_UPDATE',
  CONTENT_PUBLISH = 'CONTENT_PUBLISH',
  CONTENT_READ = 'CONTENT_READ',
  CONTENT_UPDATE = 'CONTENT_UPDATE',
  CONTENT_UPDATE_PUBLISHED = 'CONTENT_UPDATE_PUBLISHED',
  ENUMERATION_CREATE = 'ENUMERATION_CREATE',
  ENUMERATION_DELETE = 'ENUMERATION_DELETE',
  ENUMERATION_READ = 'ENUMERATION_READ',
  ENUMERATION_UPDATE = 'ENUMERATION_UPDATE',
  ENVIRONMENT_CREATE = 'ENVIRONMENT_CREATE',
  ENVIRONMENT_DELETE = 'ENVIRONMENT_DELETE',
  ENVIRONMENT_PROMOTE = 'ENVIRONMENT_PROMOTE',
  ENVIRONMENT_READ = 'ENVIRONMENT_READ',
  ENVIRONMENT_UPDATE = 'ENVIRONMENT_UPDATE',
  EXTENSION_CREATE = 'EXTENSION_CREATE',
  EXTENSION_DELETE = 'EXTENSION_DELETE',
  EXTENSION_READ = 'EXTENSION_READ',
  EXTENSION_UPDATE = 'EXTENSION_UPDATE',
  FIELD_CREATE = 'FIELD_CREATE',
  FIELD_DELETE = 'FIELD_DELETE',
  FIELD_READ = 'FIELD_READ',
  FIELD_UPDATE = 'FIELD_UPDATE',
  INTEGRATION_CREATE = 'INTEGRATION_CREATE',
  INTEGRATION_DELETE = 'INTEGRATION_DELETE',
  INTEGRATION_READ = 'INTEGRATION_READ',
  INTEGRATION_UPDATE = 'INTEGRATION_UPDATE',
  LOCALE_CREATE = 'LOCALE_CREATE',
  LOCALE_DELETE = 'LOCALE_DELETE',
  LOCALE_READ = 'LOCALE_READ',
  LOCALE_UPDATE = 'LOCALE_UPDATE',
  MANAGE_PAYMENT = 'MANAGE_PAYMENT',
  MODEL_CREATE = 'MODEL_CREATE',
  MODEL_DELETE = 'MODEL_DELETE',
  MODEL_READ = 'MODEL_READ',
  MODEL_UPDATE = 'MODEL_UPDATE',
  NETLIFY_TRIGGER_BUILD = 'NETLIFY_TRIGGER_BUILD',
  PAT_CREATE = 'PAT_CREATE',
  PAT_DELETE = 'PAT_DELETE',
  PAT_READ = 'PAT_READ',
  PAT_UPDATE = 'PAT_UPDATE',
  PLAYGROUND_USE = 'PLAYGROUND_USE',
  PROJECT_CLONE = 'PROJECT_CLONE',
  PROJECT_DELETE = 'PROJECT_DELETE',
  PROJECT_UPDATE = 'PROJECT_UPDATE',
  REMOTE_SOURCE_CREATE = 'REMOTE_SOURCE_CREATE',
  REMOTE_SOURCE_DELETE = 'REMOTE_SOURCE_DELETE',
  REMOTE_SOURCE_READ = 'REMOTE_SOURCE_READ',
  REMOTE_SOURCE_UPDATE = 'REMOTE_SOURCE_UPDATE',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_DELETE = 'ROLE_DELETE',
  ROLE_UPDATE = 'ROLE_UPDATE',
  STAGE_CREATE = 'STAGE_CREATE',
  STAGE_DELETE = 'STAGE_DELETE',
  STAGE_READ = 'STAGE_READ',
  STAGE_UPDATE = 'STAGE_UPDATE',
  STORAGE_BUCKET_CREATE = 'STORAGE_BUCKET_CREATE',
  STORAGE_BUCKET_DELETE = 'STORAGE_BUCKET_DELETE',
  STORAGE_BUCKET_READ = 'STORAGE_BUCKET_READ',
  STORAGE_BUCKET_UPDATE = 'STORAGE_BUCKET_UPDATE',
  USER_ASSIGNROLE = 'USER_ASSIGNROLE',
  USER_INVITE = 'USER_INVITE',
  USER_REMOVE = 'USER_REMOVE',
  VIEW_GROUP_CREATE = 'VIEW_GROUP_CREATE',
  VIEW_GROUP_DELETE = 'VIEW_GROUP_DELETE',
  VIEW_GROUP_READ = 'VIEW_GROUP_READ',
  VIEW_GROUP_UPDATE = 'VIEW_GROUP_UPDATE',
  VIEW_ROLE_PERMISSION_SETTINGS = 'VIEW_ROLE_PERMISSION_SETTINGS',
  VIEW_SCHEMA = 'VIEW_SCHEMA',
  VIEW_TEAM_MEMBER_SETTINGS = 'VIEW_TEAM_MEMBER_SETTINGS',
  WEBHOOK_CREATE = 'WEBHOOK_CREATE',
  WEBHOOK_DELETE = 'WEBHOOK_DELETE',
  WEBHOOK_READ = 'WEBHOOK_READ',
  WEBHOOK_UPDATE = 'WEBHOOK_UPDATE'
}

export type Plan = {
  __typename: 'Plan';
  billingPeriodMonths: Scalars['Int'];
  createdAt: Scalars['Time'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isEnterprise: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  isSwitchable?: Maybe<Scalars['Boolean']>;
  limits: Array<Limit>;
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt: Scalars['Time'];
};


export type PlanIsSwitchableArgs = {
  projectId: Scalars['ID'];
};

export type Profile = {
  __typename: 'Profile';
  companyName?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export enum ProfileJobRole {
  DEVELOPER_ENGINEERING = 'DEVELOPER_ENGINEERING',
  EDITORIAL_CONTENT = 'EDITORIAL_CONTENT',
  MANAGEMENT = 'MANAGEMENT',
  OTHER = 'OTHER',
  PROCUREMENT = 'PROCUREMENT',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  SALES = 'SALES',
  SECURITY_LEGAL = 'SECURITY_LEGAL'
}

export type Progress = {
  __typename: 'Progress';
  current: Scalars['Float'];
  estimate?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
};

export type Project = {
  __typename: 'Project';
  cloningProjects: Array<CloningProject>;
  defaultPaginationSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  environment: Environment;
  environments: Array<Environment>;
  existingRole: Role;
  existingRoles: Array<Role>;
  id: Scalars['ID'];
  inTrial?: Maybe<Scalars['Boolean']>;
  isCloning?: Maybe<Scalars['Boolean']>;
  lifecycle: Lifecycle;
  maxPaginationSize?: Maybe<Scalars['Int']>;
  meta: Scalars['JSON'];
  name: Scalars['String'];
  opensInClassic: Scalars['Boolean'];
  picture?: Maybe<Scalars['String']>;
  /** if this is `null` it means the project is not publicly clone-able */
  publicCloneAccess?: Maybe<PublicCloneAccess>;
  quotas: Quota;
  region: Region;
  status: ProjectStatus;
  subscription: PaymentSubscription;
  trialExpiresIn?: Maybe<Scalars['String']>;
};


export type ProjectEnvironmentArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type ProjectExistingRoleArgs = {
  id: Scalars['ID'];
};

export type ProjectChangeCompletedCloning = {
  __typename: 'ProjectChangeCompletedCloning';
  clonedProject: Project;
};

export type ProjectChangedPayload = ProjectChangeCompletedCloning;

export type ProjectCreatedPayload = {
  __typename: 'ProjectCreatedPayload';
  id: Scalars['ID'];
};

export type ProjectPublicClonePayload = {
  __typename: 'ProjectPublicClonePayload';
  project: Project;
};

export enum ProjectStatus {
  ALLOCATING = 'ALLOCATING',
  CREATING = 'CREATING',
  CREATION_FAILED = 'CREATION_FAILED',
  DELETED = 'DELETED',
  READY = 'READY',
  RESERVED = 'RESERVED'
}

export type ProjectUpdatePayload = {
  __typename: 'ProjectUpdatePayload';
  projectId: Scalars['String'];
  status: ProjectStatus;
};

export type PublicCloneAccess = {
  __typename: 'PublicCloneAccess';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  includeContent: Scalars['Boolean'];
  includeWebhooks: Scalars['Boolean'];
};

export type Query = {
  __typename: 'Query';
  _getUser?: Maybe<_GetUserPayload>;
  metaInfo: MetaInfo;
  viewer: IViewer;
};


export type Query_GetUserArgs = {
  email: Scalars['String'];
  sub: Scalars['String'];
};

export type Quota = {
  __typename: 'Quota';
  apiOperations: Progress;
  assetTraffic: Progress;
  environments: Progress;
  records: Progress;
  roles: Progress;
  seats: Progress;
};

export type Region = {
  __typename: 'Region';
  awsRegion: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  isBeta: Scalars['Boolean'];
  isReadOnly: Scalars['Boolean'];
  name: Scalars['String'];
  pingUrl?: Maybe<Scalars['String']>;
};

export type RejectInviteInput = {
  id: Scalars['String'];
};

export type RejectInvitePayload = {
  __typename: 'RejectInvitePayload';
  invite: Invite;
};

export type RemoveMemberInput = {
  memberId: Scalars['ID'];
};

export type RemoveMemberPayload = {
  __typename: 'RemoveMemberPayload';
  removedMemberId: Scalars['ID'];
};

export type Role = {
  __typename: 'Role';
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  managementPermissions: Array<ManagementPermission>;
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type SendFeedbackInput = {
  allowContact?: InputMaybe<Scalars['Boolean']>;
  featureName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  rating?: InputMaybe<Scalars['Int']>;
  reasons?: InputMaybe<Array<Scalars['String']>>;
  type: FeedbackType;
};

export type SendInviteInput = {
  email: Scalars['String'];
  origin?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  roleIds: Array<Scalars['ID']>;
};

export type SendInvitePayload = {
  __typename: 'SendInvitePayload';
  invite: Invite;
};

export type SendUserFeedbackInput = {
  message?: InputMaybe<Scalars['String']>;
  reasons?: InputMaybe<Array<Scalars['String']>>;
};

export type SetUserAnalyticsInput = {
  conversionPage?: InputMaybe<Scalars['String']>;
  gclid?: InputMaybe<Scalars['String']>;
  hubspotutk?: InputMaybe<Scalars['String']>;
  landingPage?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['String']>;
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export type SetUserSelectionInput = {
  /**
   * You don't need to pass the whole selection object here, just the keys you want to update or add.
   * To remove keys pass them as null, eg. pass { "hello": null } to remove "hello" from user selection object
   */
  selection?: InputMaybe<Scalars['Map']>;
};

export enum SimpleFieldType {
  BOOLEAN = 'BOOLEAN',
  COLOR = 'COLOR',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  FLOAT = 'FLOAT',
  ID = 'ID',
  INT = 'INT',
  JSON = 'JSON',
  LOCATION = 'LOCATION',
  RICHTEXT = 'RICHTEXT',
  STRING = 'STRING'
}

export type Stage = {
  __typename: 'Stage';
  apiId: Scalars['String'];
  backgroundColor: Scalars['String'];
  color: Scalars['String'];
  colorPaletteId: ColorPalette;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  position: Scalars['Int'];
  updatedAt: Scalars['Time'];
};

export type StageFilestackSecurityOptions = {
  __typename: 'StageFilestackSecurityOptions';
  expires: Scalars['String'];
  stage: Stage;
};

export type StartTrialInput = {
  planId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type StartTrialPayload = {
  __typename: 'StartTrialPayload';
  project: Project;
};

export type StarterTemplate = ITemplate & {
  __typename: 'StarterTemplate';
  coverPicture?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  resources: Array<TemplateResource>;
  stack: Array<TechnologyStack>;
};

export type Stats = {
  __typename: 'Stats';
  time: Scalars['String'];
  value: Scalars['Float'];
};

export type Subscription = {
  __typename: 'Subscription';
  inviteUpdate: InviteUpdatePayload;
  projectChanged: ProjectChangedPayload;
  projectCreated: ProjectCreatedPayload;
  projectUpdate: ProjectUpdatePayload;
};

export type SwitchPaymentSubscriptionInput = {
  planName: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

export type SwitchPaymentSubscriptionPayload = {
  __typename: 'SwitchPaymentSubscriptionPayload';
  project: Project;
  subscription: PaymentSubscription;
};

export type TechnologyStack = {
  __typename: 'TechnologyStack';
  image: Scalars['String'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type TechnologyStackInput = {
  image: Scalars['String'];
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type Template = ITemplate & {
  __typename: 'Template';
  coverPicture?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  resources: Array<TemplateResource>;
};

export type TemplateResource = {
  __typename: 'TemplateResource';
  title: Scalars['String'];
  url: Scalars['String'];
};

export type TemplateResourceInput = {
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum TrackEvent {
  CHECKED_QUICKSTART = 'CHECKED_QUICKSTART',
  CREATED_CONTENT = 'CREATED_CONTENT',
  USED_PLAYGROUND = 'USED_PLAYGROUND'
}

export type TrackInput = {
  event: TrackEvent;
  meta?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
};

export type TrackPayload = {
  __typename: 'TrackPayload';
  success: Scalars['Boolean'];
};

export type UpdateAppInput = {
  accessType?: InputMaybe<AppAccessType>;
  allowedProjects?: InputMaybe<Array<Scalars['String']>>;
  allowedUsers?: InputMaybe<Array<Scalars['String']>>;
  /** APIID of the app to update */
  apiId: Scalars['String'];
  avatarUrl?: InputMaybe<Scalars['String']>;
  configurationUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  elements?: InputMaybe<Array<AppElementInput>>;
  name?: InputMaybe<Scalars['String']>;
  setupUrl?: InputMaybe<Scalars['String']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateAppPayload = {
  __typename: 'UpdateAppPayload';
  updatedApp: AppForOwner;
};

export type UpdateAppSecretsInput = {
  apiId: Scalars['String'];
};

export type UpdateAppSecretsPayload = {
  __typename: 'UpdateAppSecretsPayload';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type UpdateFilestackSecurityOptionsInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  environmentId: Scalars['ID'];
  globalExpires?: InputMaybe<Scalars['String']>;
  stageOverrides?: InputMaybe<Array<UpdateStageFilestackSecurityOptionsInput>>;
};

export type UpdateFilestackSecurityOptionsPayload = {
  __typename: 'UpdateFilestackSecurityOptionsPayload';
  updatedEnvironment: Environment;
  updatedFilestack: Filestack;
};

export type UpdateProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  publicCloneAccess?: InputMaybe<UpdatePublicCloneAccessInput>;
};

export type UpdatePublicCloneAccessInput = {
  enabled: Scalars['Boolean'];
  includeContent: Scalars['Boolean'];
  includeWebhooks: Scalars['Boolean'];
};

export type UpdateStageFilestackSecurityOptionsInput = {
  expires: Scalars['String'];
  stageId: Scalars['ID'];
};

export type UpdateUserProfileInput = {
  company?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companySize?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<ProfileJobRole>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type UpdateUserProfilePayload = {
  __typename: 'UpdateUserProfilePayload';
  userProfile: Profile;
};

export type UpsertTemplateInput = {
  coverPicture?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  resources?: InputMaybe<Array<TemplateResourceInput>>;
  stack?: InputMaybe<Array<TechnologyStackInput>>;
};

export type UpsertTemplatePayload = {
  __typename: 'UpsertTemplatePayload';
  template: Template;
};

export type User = IUser & {
  __typename: 'User';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  profile: Profile;
};

export type UserAnalytics = {
  __typename: 'UserAnalytics';
  conversionPage?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  gclid?: Maybe<Scalars['String']>;
  hubspotutk?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  landingPage?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
};

export type UserFeedback = {
  __typename: 'UserFeedback';
  id: Scalars['ID'];
  /** Feedback Message */
  message?: Maybe<Scalars['String']>;
  /** Reason for deleting project */
  reasons?: Maybe<Array<Scalars['String']>>;
};

export type UserSelection = {
  __typename: 'UserSelection';
  selection?: Maybe<Scalars['Map']>;
};

export type UserViewer = IViewer & {
  __typename: 'UserViewer';
  app: IApp;
  apps: Array<IApp>;
  commonAssetConfig: CommonFilestack;
  id: Scalars['ID'];
  ownedProjects: Array<Project>;
  pendingInvites: Array<Invite>;
  pendingProjects: Array<Project>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  regions: Array<Region>;
  templates: Array<ITemplate>;
  user: User;
  userSelection: UserSelection;
};


export type UserViewerAppArgs = {
  apiId: Scalars['String'];
};


export type UserViewerProjectArgs = {
  id: Scalars['ID'];
};

export type _BookOverLimitAddonUsage = {
  __typename: '_BookOverLimitAddonUsage';
  amount: Scalars['Float'];
  percent: Scalars['Float'];
};

export type _BookOverLimitInput = {
  dryRun: Scalars['Boolean'];
  projectIds: Array<Scalars['ID']>;
  usageThresholdPercent?: Scalars['Int'];
};

export type _BookOverLimitPayload = {
  __typename: '_BookOverLimitPayload';
  almostOverLimit: Array<_OverLimitProject>;
  assetBlocked: Array<_OverLimitProject>;
  assetUnblocked: Array<_OverLimitProject>;
  blocked: Array<_OverLimitProject>;
  errors: Array<Scalars['String']>;
  overLimit: Array<_OverLimitProject>;
  skipped: Array<_OverLimitProject>;
  unblocked: Array<_OverLimitProject>;
  writesBlocked: Array<_OverLimitProject>;
  writesUnblocked: Array<_OverLimitProject>;
};

export type _DeleteAccountInput = {
  userEmail: Scalars['String'];
};

export type _DeleteAccountPayload = {
  __typename: '_DeleteAccountPayload';
  migrationDownScript: Scalars['String'];
  migrationUpScript: Scalars['String'];
};

export type _GetUserPayload = {
  __typename: '_GetUserPayload';
  email: Scalars['String'];
  /**
   * In case there is a SSO connection setup for the given email domain, this contains
   * the sso sub
   */
  ssoConnectionSub?: Maybe<Scalars['String']>;
  sub: Scalars['String'];
};

export type _OverLimitProject = {
  __typename: '_OverLimitProject';
  billingPeriodProgress: Scalars['Float'];
  id: Scalars['ID'];
  isFree: Scalars['Boolean'];
  name: Scalars['String'];
  usage: _OverLimitProjectUsage;
};

export type _OverLimitProjectAddons = {
  __typename: '_OverLimitProjectAddons';
  apiOperations: _OverLimitProjectAddonsValues;
  assetTraffic: _OverLimitProjectAddonsValues;
};

export type _OverLimitProjectAddonsValues = {
  __typename: '_OverLimitProjectAddonsValues';
  amount: Scalars['Float'];
  price: Scalars['Float'];
};

export type _OverLimitProjectUsage = {
  __typename: '_OverLimitProjectUsage';
  addons?: Maybe<_OverLimitProjectAddons>;
  apiOperations: _BookOverLimitAddonUsage;
  assetTraffic: _BookOverLimitAddonUsage;
  documents: _BookOverLimitAddonUsage;
};

export type _ReserveProjectsInput = {
  count: Scalars['Int'];
  region: Scalars['String'];
  template?: InputMaybe<_ReserveProjectsTemplateInput>;
};

export type _ReserveProjectsPayload = {
  __typename: '_ReserveProjectsPayload';
  projectsReserved: Scalars['Int'];
};

export type _ReserveProjectsTemplateInput = {
  content?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type _ResetContentConfigInput = {
  environmentId: Scalars['ID'];
};

export type _ResetContentConfigPayload = {
  __typename: '_ResetContentConfigPayload';
  environment: Environment;
};

export type _SwitchOwnerInput = {
  newOwnerEmail: Scalars['String'];
  projectId: Scalars['ID'];
};

export type _SwitchOwnerPayload = {
  __typename: '_SwitchOwnerPayload';
  project: Project;
};

export type _UpdatePlanTrialInput = {
  enableEnterpriseFeatures: Scalars['Boolean'];
  projectId: Scalars['ID'];
  trialExpiresIn: Scalars['String'];
};

export type _UpdatePlanTrialPayload = {
  __typename: '_UpdatePlanTrialPayload';
  project: Project;
};
