import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type RegionFragment = { __typename: 'Region', id: string, name: string, label: string, value: string, selectable: boolean };

export const RegionFragment = gql`
    fragment RegionFragment on Region {
  id
  name
  label: name
  value: id
  selectable: enabled
}
    `;