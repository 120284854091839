import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserSelectionMutationVariables = Types.Exact<{
  input: Types.SetUserSelectionInput;
}>;


export type SetUserSelectionMutation = { __typename: 'Mutation', setUserSelection: { __typename: 'UserSelection', selection?: any | null | undefined } };


export const SetUserSelectionMutationDocument = gql`
    mutation SetUserSelectionMutation($input: SetUserSelectionInput!) {
  setUserSelection(data: $input) {
    selection
  }
}
    `;

/**
 * __useSetUserSelectionMutation__
 *
 * To run a mutation, you first call `useSetUserSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSelectionMutation, { data, loading, error }] = useSetUserSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserSelectionMutation(baseOptions?: Apollo.MutationHookOptions<SetUserSelectionMutation, SetUserSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserSelectionMutation, SetUserSelectionMutationVariables>(SetUserSelectionMutationDocument, options);
      }
export type SetUserSelectionMutationHookResult = ReturnType<typeof useSetUserSelectionMutation>;