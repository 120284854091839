import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSelectionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserSelectionQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, userSelection: { __typename: 'UserSelection', selection?: any | null | undefined } } };


export const UserSelectionQueryDocument = gql`
    query userSelectionQuery {
  viewer {
    id
    ... on UserViewer {
      userSelection {
        selection
      }
    }
  }
}
    `;

/**
 * __useUserSelectionQuery__
 *
 * To run a query within a React component, call `useUserSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSelectionQuery(baseOptions?: Apollo.QueryHookOptions<UserSelectionQuery, UserSelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSelectionQuery, UserSelectionQueryVariables>(UserSelectionQueryDocument, options);
      }
export function useUserSelectionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSelectionQuery, UserSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSelectionQuery, UserSelectionQueryVariables>(UserSelectionQueryDocument, options);
        }
export type UserSelectionQueryHookResult = ReturnType<typeof useUserSelectionQuery>;
export type UserSelectionQueryLazyQueryHookResult = ReturnType<typeof useUserSelectionQueryLazyQuery>;