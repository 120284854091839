import {
  project as projectValidation,
  object as yupObject,
  string as yupString,
} from '@graphcms/validation';
import { trans } from 'i18n';
import { useFeatureFlag } from 'modules/launchDarkly/useFeatureFlag';
import { useGetRegions } from './useGetRegions';

export const useProjectSchemaValidation = () => {
  const { regions } = useGetRegions();
  const defaultRegionsEnabled = useFeatureFlag('enableDefaultRegions');
  const selectableRegions =
    regions?.filter(r => r.selectable).map(item => item.id) ?? [];

  let regionValidator = yupString().min(1, trans('Region is a required field'));
  if (defaultRegionsEnabled) {
    regionValidator = regionValidator.oneOf(
      selectableRegions,
      ({ value }) => `The selected region "${value}" is a paid-only region`
    );
  }

  const validator = yupObject().shape({
    name: projectValidation.name.required(),
    description: projectValidation.description.nullable(),
    region: regionValidator,
  });

  return { validator };
};
