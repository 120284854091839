import { OverlayProvider, Progress } from '@hygraph/baukasten';
import { AccountSettings } from 'components/Account/AccountSettings';
import { ModalProvider } from 'components/Modal/ModalContext';
import ModalRoot from 'components/Modal/ModalRoot';
import ProjectList from 'components/ProjectList';
import { MigratingDialog } from 'components/ProjectList/MigratingDialog';
import { useGetStudioOrClassicURL } from 'hooks/useGetStudioOrClassicURL';
import { trans } from 'i18n';
import { Apps } from 'modules/apps';
import { ClonePublicProjectContainer } from 'modules/projects/components/ClonePublicProjectContainer';
import { regionalBaseUrl } from 'modules/projects/helpers/regionalProjectUrl';
import { useProject } from 'modules/projects/hooks/useProject';
import { useRudderstackIdentifyUser } from 'modules/tracking/hooks/useTrackRudderstack';
import { useUser } from 'modules/user/hooks/useUser';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import { Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { InviteCode } from './InviteCode';
import { Welcome } from './Welcome/Welcome';
/**
 * All routes in this component must assume that the user is logged in.
 * If a route is supposed to (also) work when a user is logged-out, it should be placed in App.tsx
 */
export function AuthenticatedRoutes() {
  // Identify User in Rudderstack for UI event tracking
  useRudderstackIdentifyUser();
  const { setIdentity, setTrackPageView } = useTrackingCode();

  const { user } = useUser();
  if (user) {
    setIdentity(user.profile.email);
    setTrackPageView();
  }

  return (
    <OverlayProvider className=".root">
      <Switch>
        <CompatRoute
          exact
          path="/code/:code"
          render={({ match }) => <InviteCode inviteCode={match.params.code} />}
        />
        <CompatRoute
          path="/apps" // FIX ME *
          render={() => {
            return <Apps />;
          }}
        />
        <CompatRoute
          exact
          path="/" // FIX ME index
          render={() => <ProjectList />}
        />
        <CompatRoute
          exact
          path="/clone/:publicCloneAccessId"
          render={({
            match: {
              params: { publicCloneAccessId },
            },
          }) => (
            <ClonePublicProjectContainer
              publicCloneAccessId={publicCloneAccessId}
            />
          )}
        />
        {/* <CompatRoute exact path="/welcome" render={() => <Onboarding />} /> */}
        <CompatRoute
          exact
          path="/account"
          render={() => (
            <ModalProvider>
              <ModalRoot />
              <AccountSettings />
            </ModalProvider>
          )}
        />
        <CompatRoute
          exact
          path="/welcome"
          render={() => (
            <ModalProvider>
              <ModalRoot />
              <Welcome />
            </ModalProvider>
          )}
        />
        <CompatRoute
          exact
          path="/pending/:projectId"
          render={({
            match: {
              params: { projectId },
            },
          }) => <MigratingDialog projectId={projectId} />}
        />
        <CompatRoute
          path="/:projectId/:environment"
          render={({
            match: {
              params: { projectId, environment },
            },
          }) => (
            <RedirectToProject
              projectId={projectId}
              environment={environment}
            />
          )}
        />
        <CompatRoute
          path="/:projectId"
          render={({
            match: {
              params: { projectId },
            },
          }) => <RedirectToProject projectId={projectId} />}
        />
      </Switch>
    </OverlayProvider>
  );
}

function RedirectToProject({ projectId, environment = undefined }) {
  const currentURL = new URL(window.location.href);

  const { viewer, loading } = useProject(projectId);
  const { getUrl } = useGetStudioOrClassicURL();
  if (loading) return <Progress variant="slim" />;

  if (!viewer?.project) {
    throw new Error(
      trans(
        'Failed to load the project, please check that it the valid project url and that you have access to it'
      )
    );
  }

  // If an environment is specified, there's a likelihood that the path has context, hence, we should redirect to the regional url
  // with the `pathname` as the context. Otherwise, we should redirect to the regional project url with the master environment as context.
  const redirectURL = new URL(
    environment
      ? regionalBaseUrl(viewer.project.region.id) + currentURL.pathname
      : getUrl(viewer.project, environment)
  );

  redirectURL.search = currentURL.search;

  window.location.replace(redirectURL);

  return null;
}
