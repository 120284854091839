import { useManagementApiClient } from 'modules/managementApi';
import { RegionFragment } from 'modules/projects/gql/generated/RegionFragment';
import { useRegionsQuery } from 'modules/projects/gql/generated/regionsQuery';
import { ReactComponent as AustraliaFlag } from '../../assets/australia.svg';
import { ReactComponent as BrazilFlag } from '../../assets/brazil.svg';
import { ReactComponent as CanadaFlag } from '../../assets/canada.svg';
import { ReactComponent as GermanyFlag } from '../../assets/germany.svg';
import { ReactComponent as IndiaFlag } from '../../assets/india.svg';
import { ReactComponent as JapanFlag } from '../../assets/japan.svg';
import { ReactComponent as UkFlag } from '../../assets/uk.svg';
import { ReactComponent as UsFlag } from '../../assets/us.svg';

export type Region = {
  id: string;
  label?: string;
  icon?: typeof AustraliaFlag;
  city?: string;
  latitude?: string;
  longitude?: string;
  selectable?: boolean;
};

const allRegions: Region[] = [
  {
    id: 'AP-NORTHEAST-1',
    icon: JapanFlag,
    label: 'Japan (Tokyo)',
    city: 'Tokyo',
    latitude: '35.6762',
    longitude: '139.6503',
  },
  {
    id: 'AP-SOUTH-1',
    icon: IndiaFlag,
    city: 'Mumbai',
    label: 'India (Mumbai)',
    latitude: '19.0760',
    longitude: '72.8777',
  },
  {
    id: 'AP-SOUTHEAST-2',
    icon: AustraliaFlag,
    city: 'Sydney',
    label: 'Australia (Sydney)',
    latitude: '-33.8688',
    longitude: '151.2093',
  },
  {
    id: 'CA-CENTRAL-1',
    icon: CanadaFlag,
    city: 'Montreal',
    label: 'Canada (Montreal)',
    latitude: '45.5017',
    longitude: '-73.5673',
  },
  {
    id: 'EU-CENTRAL-1-SHARED-EUC1-02',
    icon: GermanyFlag,
    city: 'Frankfurt',
    label: 'Germany (Frankfurt)',
    latitude: '50.1109',
    longitude: '8.6821',
  },
  {
    id: 'EU-WEST-2',
    icon: UkFlag,
    city: 'London',
    label: 'United Kingdom (London)',
    latitude: '51.5072',
    longitude: '0.1276',
  },
  {
    id: 'SA-EAST-1',
    icon: BrazilFlag,
    city: 'São Paulo',
    label: 'Brazil (São Paulo)',
    latitude: '-23.5558',
    longitude: '-46.6396',
  },
  {
    id: 'US-EAST-1-SHARED-USEA1-02',
    icon: UsFlag,
    city: 'N. Virginia',
    label: 'US East (N. Virginia)',
    latitude: '37.4316',
    longitude: '-78.6569',
  },
  {
    id: 'US-WEST-2',
    icon: UsFlag,
    city: 'Oregon',
    label: 'US West (Oregon)',
    latitude: '43.8041',
    longitude: '-120.5542',
  },
  { id: 'LOCAL' },
  { id: 'LOCAL-1' },
  { id: 'LOCAL-2' },
  { id: 'LOCAL3' },
];

export function useGetRegions() {
  const client = useManagementApiClient();

  const { loading, data } = useRegionsQuery({
    client,
  });

  const regions = data && (data.viewer.regions as RegionFragment[]);

  const parsedRegions = regions?.map(({ id, selectable }) => {
    const region = allRegions.find(region => region.id === id);
    return {
      id,
      label: region?.label,
      icon: region?.icon,
      selectable,
    };
  });

  const knownRegions = parsedRegions?.filter(({ id }) =>
    regions?.some(region => region.id === id)
  );

  return {
    loading,
    regions: knownRegions,
  };
}
