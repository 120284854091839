import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Heading,
  Inline,
} from '@hygraph/baukasten';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { trans } from 'i18n';
import { useClonePublicProject } from 'modules/projects/hooks/useClonePublicProject';
import { CloneProjectForm } from './CloneProjectForm';

import { useGetRegions } from 'modules/projects/components/CreateProject/useGetRegions';
import { CloneProjectMutationVariables } from 'modules/projects/gql/generated/cloneProjectMutation';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { validateWithYup } from 'utils';
import { useProjectSchemaValidation } from './CreateProject/useProjectSchemaValidation';

interface Props {
  publicCloneAccessId: string;
}

export const ClonePublicProjectDialog = (props: Props) => {
  const { publicCloneAccessId } = props;
  const { regions, loading } = useGetRegions();
  const [clonePublicProjectMutation, clonePublicProjectMutationState] =
    useClonePublicProject();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { validator } = useProjectSchemaValidation();

  useEffect(() => {
    rudderstackTrack(Events.CREATE_PROJECT, {
      type: 'clone public project',
      public_access_id: publicCloneAccessId,
    });
  }, [publicCloneAccessId]);

  if (loading || !regions) return null;

  const sourceProjectName = decodeURIComponent(
    (search.split('?name=')[1] || '').split('&')[0]
  );

  return (
    <Dialog isOpen aria-labelledby="clone-project-header" maxWidth="800px">
      <Form<CloneProjectMutationVariables>
        validate={validateWithYup(validator)}
        initialValues={{
          name: trans('Clone of {{name}}', { name: sourceProjectName }),
          description: '',
          region: regions[0].id,
        }}
        subscription={{
          submitting: true,
          submitError: true,
        }}
        onSubmit={values => {
          rudderstackTrack(Events.PROJECT_CREATING, {
            public_access_id: publicCloneAccessId,
            type: 'public project cloning',
          });
          clonePublicProjectMutation({
            data: {
              publicCloneAccessId,
              name: values.name,
              description: values.description,
              region: values.region,
            },
          }).then(result => {
            if (result?.project?.id) {
              navigate(`/pending/${result.project.id}?region=${values.region}`);
              rudderstackTrack(Events.PROJECT_CREATED, {
                public_access_id: publicCloneAccessId,
                type: 'public project cloned',
                project_id: result.project.id,
              });
            }
          });
        }}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <Box as="form" onSubmit={handleSubmit}>
              {submitError && (
                <ApolloInlineError
                  error={clonePublicProjectMutationState.error}
                >
                  {(err, key) => (
                    <Alert mb="m" key={key}>
                      {err.message || err}
                    </Alert>
                  )}
                </ApolloInlineError>
              )}

              <DialogHeader>
                <Heading as="h4" fontWeight="medium" id="clone-project-header">
                  {/* TRANS component refactor */}
                  {trans('Clone')} <b>{sourceProjectName}</b> {trans('project')}
                </Heading>
              </DialogHeader>
              <DialogContent>
                <CloneProjectForm regions={regions} hideCloneOptions />
              </DialogContent>
              <DialogFooter>
                <Inline justifyContent="flex-end">
                  <Button
                    type="submit"
                    role="submit"
                    disabled={submitting}
                    size="large"
                    loading={submitting}
                    onClick={() => {
                      rudderstackTrack(Events.PROJECT_CREATING, {
                        type: 'empty project creating',
                      });
                    }}
                    loadingText={trans('Cloning')}
                    data-test="CreateProjectButton"
                  >
                    {trans('Clone now')}
                  </Button>
                </Inline>
              </DialogFooter>
            </Box>
          );
        }}
      </Form>
    </Dialog>
  );
};
