import { useLaunchDarkly } from './useLaunchDarkly';

/**
 * @see https://www.notion.so/hygraph/Feature-Flags-9e37f561cc7341959e2d1afced5db434
 */
export type FeatureFlags = {
  // LaunchDarkly flags
  enableDefaultRegions?: boolean;
};

export function useFeatureFlag<T extends keyof FeatureFlags>(flagName: T) {
  const { success, LDClient } = useLaunchDarkly();

  // LaunchDarkly flags
  const launchDarklyFlags = success ? LDClient?.allFlags() : {}; // INFO: Only call LaunchDarkly if LD.identify was successful (see https://linear.app/graphcms/issue/GRO-235/launch-darkly-mau-are-growing-and-growing)

  return launchDarklyFlags?.[flagName] as FeatureFlags[T];
}
