import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import { useSetUserSelectionMutation } from 'modules/user/gql/generated/setUserSelection';
import { UserSelection } from 'modules/user/hooks/useUserSelection';

export function useSetUserSelection() {
  const client = useManagementApiClient();

  const [setUserSelectionMutation, response] = useSetUserSelectionMutation({
    client,
  });

  const setUserSelection = useCallback(
    async function (selection: UserSelection) {
      const value = await setUserSelectionMutation({
        variables: { input: { selection } },
      });
      return value.data && value.data.setUserSelection.selection;
    },
    [setUserSelectionMutation]
  );

  return [
    setUserSelection,
    {
      loading: response.loading,
      userSelection: response.data && response.data.setUserSelection.selection,
      error: response.error,
    },
  ] as const;
}
