import { useManagementApiClient } from 'modules/managementApi';
import { useUserSelectionQuery } from '../gql/generated/userSelectionQuery';

export type UserSelection = {
  showWelcomeSurvey?: boolean;
} | null;

export function useUserSelection(skip?: boolean) {
  const client = useManagementApiClient();
  const { loading, data, error } = useUserSelectionQuery({
    client,
    skip,
  });

  if (error) {
    throw error;
  }

  return {
    loading,
    error,
    userSelection:
      data && (data.viewer.userSelection.selection as UserSelection),
  };
}
