import {
  Alert,
  CheckBox,
  StackOld,
  TextArea,
  Input,
  Combobox,
} from '@hygraph/baukasten';
import { useForm } from 'react-final-form';

import { GField } from 'components/GField';
import { CheckboxGField } from 'components/GField/CheckboxGField';
import { useGetRegions } from 'modules/projects/components/CreateProject/useGetRegions';
import { PRODUCT_NAME } from 'env';
import type { StarterTemplate, Template } from 'generated/graphql';
import { trans } from 'i18n';
import { getRegionSelectItems } from 'modules/projects/utils/getRegionSelectItems';

export interface CreateProjectTemplateFieldsProps {
  template?: Template | StarterTemplate;
  defaultRegionsEnabled?: boolean;
}

export function CreateProjectTemplateFields(
  props: CreateProjectTemplateFieldsProps
) {
  const { template, defaultRegionsEnabled } = props;
  const { regions } = useGetRegions();
  const { change } = useForm();

  const regionItems = getRegionSelectItems(regions, defaultRegionsEnabled);

  return (
    <StackOld space="m">
      <GField
        name="name"
        label={trans('Project name')}
        render={({ input, meta }) => (
          <Input
            {...input}
            onFocus={e => {
              if (!meta.touched && !meta.submitting) change(input.name, ''); // INFO: clear input value on first focus as we are pre-filling its value
              input.onFocus?.(e);
            }}
            data-test="createProjectName"
          />
        )}
      />
      <GField
        name="description"
        label={trans('Project description')}
        isOptional
        render={props => (
          <TextArea
            {...props.input}
            rows={3}
            data-test="createProjectDescription"
          />
        )}
      />
      {template?.id && (
        <CheckboxGField
          name="includeTemplateContent"
          label={trans('Include template content')}
          hint={trans(
            'This option allows you to import existing template content into your project.'
          )}
          render={({ input }) => (
            <CheckBox
              {...input}
              checked={input.value}
              onChange={event => input.onChange(event.target.checked)}
            />
          )}
        />
      )}
      <GField
        name="region"
        label={trans('Region')}
        render={({ input, meta: { touched, error } }) => (
          <Combobox
            {...input}
            clearable={false}
            hasError={touched && !!error}
            selectedItem={input.value}
            items={regionItems}
            comboboxInputProps={{
              id: 'CreateProjectRegion',
              placeholder: trans('Select region'),
            }}
          />
        )}
      />
      <Alert variantColor="info" showStatusIcon>
        {trans(
          '{{productName}} allows you to select which region to host your content in. Note: All content will be served from a globally distributed CDN.',
          { productName: PRODUCT_NAME }
        )}
      </Alert>
    </StackOld>
  );
}
