import { Box, ComboboxItemType } from '@hygraph/baukasten';
import { trans } from 'i18n';

import type { Region } from 'modules/projects/components/CreateProject/useGetRegions';

export function getRegionSelectItems(
  regions: Region[] | undefined,
  defaultRegionsEnabled?: boolean
): ComboboxItemType[] {
  return regions
    ? regions?.map(region => {
        let label = region.label ?? region.id;
        if (!region.selectable && defaultRegionsEnabled) {
          label = `${label} (${trans('This is a paid-only region')})`;
        }

        return {
          label,
          value: region.id,
          ...(defaultRegionsEnabled ? { disabled: !region.selectable } : {}),
          ...(region?.icon
            ? { Component: () => <Box as={region.icon} mr="xs" /> }
            : {}),
        };
      })
    : [];
}
