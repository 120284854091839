import { UseUserContext } from 'hooks/useUser';
import { UseLaunchDarklyProvider } from 'modules/launchDarkly/useLaunchDarkly';
import { useUser } from 'modules/user/hooks/useUser';
import { ClonePublicProjectDialog } from './ClonePublicProjectDialog';

interface Props {
  publicCloneAccessId: string;
}

export const ClonePublicProjectContainer = ({ publicCloneAccessId }: Props) => {
  const { user } = useUser();

  return (
    <UseUserContext.Provider value={user}>
      <UseLaunchDarklyProvider>
        <ClonePublicProjectDialog publicCloneAccessId={publicCloneAccessId} />
      </UseLaunchDarklyProvider>
    </UseUserContext.Provider>
  );
};
